<template>
  <v-row class="fill-height">
    <v-col
        cols="12"
        v-if="!received"
    >
      <v-row>
        <v-col>
          <h1 class="text-center">We're here to help!</h1>
          <h2 class="text-center mb-2">Send us a message.</h2>
          <p class="text-center"><strong><u>Do not</u></strong> submit any personal information, we know who you are, and we will get back to you via a text message.</p>
          <v-card class="mt-6 pb-3">
            <v-card-text class="px-6">
              <v-row>
                <v-col
                    class="py-0"
                >
                  <v-textarea
                      hide-details
                      auto-grow
                      color="secondary"
                      label="Your Message"
                      :disabled="isLoading"
                      name="message"
                      v-model="message"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
              block
              :loading="isLoading"
              :disabled="isLoading"
              depressed
              color="primary"
              @click="submit"
              x-large
          >
            Submit
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col
        v-else
        cols="12"
        class="text-center"
    >
      <h1>We got your message!</h1>
      <h3>We are going to get back to you within 48 hours.<br/>You can close this window.</h3>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Contact',
  data: () => ({
    message: '',
    isLoading: false,
    received: false
  }),
  computed: {
    ...mapGetters(['getToken'])
  },
  methods: {
    ...mapActions(['submitMessage', 'setAlerts']),
    async submit () {
      if (this.message && this.getToken) {
        if (this.message.length > 0 && this.getToken.length > 0) {
          try {
            await this.submitMessage({
              token: this.getToken,
              message: this.message
            })

            this.received = true
          } catch (error) {
            console.log(error)
            this.setAlerts({
              active: true,
              message: 'Sending your message failed, we\'re having issues with our servers, please try again later.',
              color: 'error'
            })
          }
        }
      }
    }
  },
};
</script>
